<template>
	<div class="tabulation" v-loading="loading" element-loading-text="加载中"
		element-loading-background="rgb(255, 255, 255, 0.5)">
		<el-card class="chart-card">
			<div style="margin-top: 20px;">
				<el-form ref="ruleFormRef" :model="form" :rules="rules" class="demo-ruleForm" :size="formSize"
					status-icon>
					<!-- 标题 -->
					<el-form-item label="标题名称" prop="name">
						<div class="aaaa">
							<el-input v-model="form.name" placeholder="请填写标题" size="large"></el-input>
						</div>
					</el-form-item>
					<!-- 标题图片 -->
					<el-form-item label="主题图片" prop="breviary">
						<el-upload action="/admin/child/saveImage" list-type="picture-card" limit="2" ref="qingkong"
							v-model="form.breviary" :auto-upload="true" :on-change="change" :on-remove="Remove"
							:class="styA==0&&styD==0?'styB':'styC'" :before-upload="beforeImageUpload"
							:before-remove="beforeremove" :on-preview="onpreview" :headers="header" :file-list="approve"
							accept=".jpeg,.png,.jpg,.bmp,.gif" :data='{
              imageFile: imageFile
              }' name="imageFile" :on-success="succ">
							<el-icon>
								<Plus />
							</el-icon>
						</el-upload>

						<el-dialog v-model="dialogVisible">
							<img w-full :src="dialogImageUrl" alt="Preview Image" style="width:100%" />
						</el-dialog>
						<div class="referrals">
							( 推荐：图片尺寸 宽700px、高400px。 )
						</div>
					</el-form-item>
					<!-- 报名时间 -->
					<el-form-item label="报名时间" prop="value1">
						<div class="bilke">
							<el-date-picker size="large" v-model="form.value1" type="datetimerange" range-separator="截至"
								start-placeholder="开始时间" end-placeholder="结束时间" :default-time="defaultTime2" />
						</div>
					</el-form-item>
					<!-- 生日限制 -->
					<el-form-item label="生日限制" prop="value2">
						<div class="bilke">
							<el-date-picker size="large" v-model="form.value2" type="datetimerange" range-separator="截至"
								start-placeholder="开始时间" end-placeholder="结束时间" :default-time="defaultTime2" />
						</div>
					</el-form-item>
					<!-- 请选择组织 -->
					<el-form-item label="所属工会" prop="choose">
						<div class="aaaa">
							<el-input v-model="form.choose" readonly placeholder="请选择所属工会"
								@click="visible = true"></el-input>
						</div>
					</el-form-item>
					<!-- 是否发布 -->
					<el-form-item label="是否发布" prop="are">
						<el-select v-model="form.are" placeholder="请选择是否发布" size="medium">
							<el-option label="是" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="否" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 报名说明 -->
					<!-- <el-form-item label="报名说明" prop="remark">
						<el-input v-model="form.remark" type="textarea" placeholder="请填写报名说明"></el-input>
					</el-form-item> -->
					<!-- 收费方式 -->
					<el-form-item label="收费方式" prop="classify">
						<el-select v-model="form.classify" @change="manner" placeholder="请选择收费方式" size="medium">
							<el-option label="二维码收款" value="1" style="width:100%; text-align-last: center;"></el-option>
							<el-option label="小程序收款" value="2" style="width:100%; text-align-last: center;"></el-option>
						</el-select>
					</el-form-item>
					<!-- 微信商户号 -->
					<el-form-item label="微信商户号" prop="commercial" v-show="behaviour"
						:rules="{required:form.classify == '2'||form.classify == '小程序收款'? true:false,message:'请填写微信商户号'}">
						<div class="aaaa">
							<el-input v-model="form.commercial" placeholder="请填写微信商户号"></el-input>
						</div>
					</el-form-item>
					<!-- 商户支付KEY -->
					<el-form-item label="商户支付KEY" prop="payment" v-show="behaviour"
						:rules="{required:form.classify == '2'||form.classify == '小程序收款'? true:false,message:'请填写商户支付KEY'}">
						<div class="aaaa">
							<el-input v-model="form.payment" placeholder="请填写商户支付KEY"></el-input>
						</div>
					</el-form-item>
					<!-- 收款二维码 -->
					<el-form-item label="收款二维码" prop="scanning" v-show="behavior"
						:rules="{required:form.classify == '1'||form.classify == '二维码收款'? true:false,message:'请上传收款码'}">
						<el-upload action="/admin/child/saveImage" list-type="picture-card" limit="2" ref="qingkong1"
							v-model="form.scanning" :auto-upload="true" :on-change="change1" :on-remove="Remove1"
							:class="styA1==0&&styD1==0?'styB':'styC'" :before-upload="beforeImageUpload1"
							:before-remove="beforeremove1" :on-preview="onpreview1" :headers="header1"
							:file-list="approve1" accept=".jpeg,.png,.jpg,.bmp,.gif" :data='{
              imageFile: imageFile
              }' name="imageFile" :on-success="succ1">
							<el-icon>
								<Plus />
							</el-icon>
						</el-upload>

						<el-dialog v-model="dialogVisible1">
							<img w-full :src="dialogImageUrl1" alt="Preview Image" style="width:100%" />
						</el-dialog>
						<!-- <div class="referrals">
            ( 推荐：图片尺寸 宽700px、高400px。 )
          </div> -->
					</el-form-item>
					<!-- 报名说明 -->
					<el-form-item prop="remark">
						<div class="subject">
							<span class="xingxing">*</span>
							报名说明
							<span class="referrals">( 推荐：图片体积小于1M。)</span>
						</div>
						<div style="border: 1px solid #ccc; width:100%">
							<Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef"
								:defaultConfig="toolbarConfig" :mode="mode" />

							<Editor style="height: 500px; overflow-y: hidden" v-model="valueHtml"
								:defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" />
						</div>
					</el-form-item>
				</el-form>
			</div>
			<el-button type="primary" size="large" style="margin-top: 20px;" :loading="ConfirmLoading"
				@click="onSubmit">提交</el-button>
		</el-card>
		<div>
			<s3-layer v-model="visible" title="所属组织" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
				<el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />
			</s3-layer>
		</div>
	</div>
</template>
<script>
	import '@wangeditor/editor/dist/css/style.css' // 引入 css
	import {
		onBeforeUnmount,
		ref,
		shallowRef,
		onMounted,
		reactive,
		onActivated
	} from 'vue'
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	import {
		Plus,
	} from '@element-plus/icons-vue'
	import * as imageConversion from 'image-conversion'
	import {
		ElMessage
	} from "element-plus";
	import {
		ZNcompile,
		ZClist,
		ZNeditx
	} from "../../utils/api"
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import moments from 'moment'
	import {
		useStore
	} from "vuex";
	// import { Pictures } from '../../utils/api'
	import qs from 'qs'
	export default {
		name: "ZNBMredact",
		components: {
			Editor,
			Toolbar,
			Plus,
		},
		setup() {
			// 加载中
			const loading = ref(true)
			const route = useRoute();
			// 是否滚动
			let cat = ref('hidden')
			let texture1 = ref([])
			const suoshu = () => {
				ZClist().then((res) => {
					texture1.value = res.data.data.manageUnionList
					const arr3 = texture1.value.filter(function(num) {
						return num.unionCode == hqcode.value
					})
					if (arr3.length == 0) {
						texture1.value.forEach((item) => {
							const arr4 = item.unionDtoList.filter(function(num) {
								return num.unionCode == hqcode.value
							})
							if (arr4.length == 0) {
								item.unionDtoList.forEach((items) => {
									const arr5 = items.unionDtoList.filter(function(nums) {
										return nums.unionCode == hqcode.value
									})
									if (arr5.length == 0) {
										items.unionDtoList.forEach((ite) => {
											const arr6 = ite.unionDtoList.filter(
												function(numss) {
													return numss.unionCode ==
														hqcode.value
												})
											if (arr6.length == 0) {
												ite.unionDtoList.forEach((it) => {
													const arr7 = it
														.unionDtoList.filter(
															function(numsss) {
																return numsss
																	.unionCode ==
																	hqcode
																	.value
															})
													if (arr7.length == 0) {
														it.unionDtoList
															.forEach((i) => {
																const
																	arr8 =
																	i
																	.unionDtoList
																	.filter(
																		function(
																			numssss
																		) {
																			return numssss
																				.unionCode ==
																				hqcode
																				.value
																		})
																if (arr8
																	.length ==
																	0) {
																	i.unionDtoList
																		.forEach(
																			(
																				s
																				) => {
																				const
																					arr9 =
																					s
																					.unionDtoList
																					.filter(
																						function(
																							nu
																						) {
																							return nu
																								.unionCode ==
																								hqcode
																								.value
																						}
																					)
																				if (arr9
																					.length ==
																					0
																				) {
																					s.unionDtoList
																						.forEach(
																							(
																								a
																								) => {
																								const
																									arr10 =
																									a
																									.unionDtoList
																									.filter(
																										function(
																											cat
																										) {
																											return cat
																												.unionCode ==
																												hqcode
																												.value
																										}
																									)
																								if (arr10
																									.length ==
																									0
																								) {
																									a.unionDtoList
																										.forEach(
																											(
																												d
																												) => {
																												const
																													arr11 =
																													d
																													.unionDtoList
																													.filter(
																														function(
																															dog
																														) {
																															return dog
																																.unionCode ==
																																hqcode
																																.value
																														}
																													)
																												if (arr11
																													.length ==
																													0
																												) {
																													d.unionDtoList
																														.forEach(
																															(
																																f
																																) => {
																																const
																																	arr12 =
																																	f
																																	.unionDtoList
																																	.filter(
																																		function(
																																			long
																																		) {
																																			return long
																																				.unionCode ==
																																				hqcode
																																				.value
																																		}
																																	)
																																arr12
																																	.forEach(
																																		(
																																			item
																																			) => {
																																			form.choose =
																																				arr3
																																				.unionName =
																																				item
																																				.unionName
																																			gaincode
																																				.value =
																																				arr3
																																				.unionCode =
																																				item
																																				.unionCode
																																		}
																																	)
																															}
																														)
																												}
																												arr11
																													.forEach(
																														(
																															item
																															) => {
																															form.choose =
																																arr3
																																.unionName =
																																item
																																.unionName
																															gaincode
																																.value =
																																arr3
																																.unionCode =
																																item
																																.unionCode
																														}
																													)
																											}
																										)
																								}
																								arr10
																									.forEach(
																										(
																											item
																											) => {
																											form.choose =
																												arr3
																												.unionName =
																												item
																												.unionName
																											gaincode
																												.value =
																												arr3
																												.unionCode =
																												item
																												.unionCode
																										}
																									)
																							}
																						)
																				}
																				arr9.forEach(
																					(
																						item
																						) => {
																						form.choose =
																							arr3
																							.unionName =
																							item
																							.unionName
																						gaincode
																							.value =
																							arr3
																							.unionCode =
																							item
																							.unionCode
																					}
																				)
																			}
																		)
																}
																arr8.forEach(
																	(
																		item
																		) => {
																		form.choose =
																			arr3
																			.unionName =
																			item
																			.unionName
																		gaincode
																			.value =
																			arr3
																			.unionCode =
																			item
																			.unionCode
																	})
															})
													}
													arr7.forEach((item) => {
														form.choose =
															arr3
															.unionName =
															item
															.unionName
														gaincode
															.value =
															arr3
															.unionCode =
															item
															.unionCode
													})
												})
											}
											arr6.forEach((item) => {
												form.choose = arr3.unionName =
													item.unionName
												gaincode.value = arr3
													.unionCode = item.unionCode
											})
										})
									}
									console.log(arr5)
									arr5.forEach((item) => {
										form.choose = arr3.unionName = item.unionName
										gaincode.value = arr3.unionCode = item
											.unionCode
									})
								})
							} else {
								arr4.forEach((item) => {
									form.choose = arr3.unionName = item.unionName
									gaincode.value = arr3.unionCode = item.unionCode
								})
							}
						})
					} else {
						arr3.forEach((item) => {
							form.choose = arr3.unionName = item.unionName
							gaincode.value = arr3.unionCode = item.unionCode
						})
					}
					loading.value = false
					// cat.value='auto'
					// console.log(res)
				})
			}



















			// 开始时间
			let staet = ref('')
			// 结束时间
			let enables = ref('')
			// 生日限制开始时间
			let staet1 = ref('')
			// 生日限制结束时间
			let enables1 = ref('')
			// 二维码图片地址
			const styD1 = ref(1)
			let approve1 = ref([{}])
			// 标题图片地址
			const styD = ref(1)
			let approve = ref([{}])
			// 获取组织code
			let hqcode = ref('')
			// 获取编辑信息
			const copyreader = () => {
				let data = {
					childCustodyId: sessionStorage.getItem('ZNBMredact')
				}
				ZNeditx(qs.stringify(data)).then((res) => {
					if (res.data.code == 0) {
						// styA1.value=0
						styD1.value = 1
						styD.value = 1
						// 标题
						form.name = res.data.data.childCustodyInfo.title
						// 标题图片
						approve.value[0].url = res.data.data.childCustodyInfo.imageInfo
						form.breviary = res.data.data.childCustodyInfo.imageInfo
						// 时间
						staet.value = moments(res.data.data.childCustodyInfo.startTime * 1000).format(
							'YYYY-MM-DD HH:mm:ss')
						enables.value = moments(res.data.data.childCustodyInfo.endTime * 1000).format(
							'YYYY-MM-DD HH:mm:ss')
						// initiation.value=res.data.data.childCustodyInfo.startTime
						// terminate.value=res.data.data.childCustodyInfo.endTime
						form.value1.push(staet.value, enables.value)
						// console.log(staet.value)
						// 生日限制
						staet1.value = moments(res.data.data.childCustodyInfo.birthdayStartTime * 1000).format(
							'YYYY-MM-DD HH:mm:ss')
						enables1.value = moments(res.data.data.childCustodyInfo.birthdayEndTime * 1000).format(
							'YYYY-MM-DD HH:mm:ss')
						// initiation.value=res.data.data.childCustodyInfo.startTime
						// terminate.value=res.data.data.childCustodyInfo.endTime
						form.value2 = [staet1.value, enables1.value]
						// 所属工会
						hqcode.value = res.data.data.childCustodyInfo.unionCode
						suoshu()
						// 是否发布
						if (res.data.data.childCustodyInfo.state == 1) {
							form.are = '是'
						} else if (res.data.data.childCustodyInfo.state == 2) {
							form.are = '否'
						}
						// 说明
						// form.remark = res.data.data.childCustodyInfo.reason
						valueHtml.value = res.data.data.childCustodyInfo.reason
						// 收款方式
						if (res.data.data.childCustodyInfo.payType == 1) {
							form.classify = '二维码收款'
							behavior.value = true
							behaviour.value = false
						} else if (res.data.data.childCustodyInfo.payType == 2) {
							form.classify = '小程序收款'
							behavior.value = false
							behaviour.value = true
						}
						// 二维码
						approve1.value[0].url = res.data.data.childCustodyInfo.payImage
						form.scanning = res.data.data.childCustodyInfo.payImage
						// 微信商户
						form.commercial = res.data.data.childCustodyInfo.mchId
						// 商户key
						form.payment = res.data.data.childCustodyInfo.mchKey
					}
					console.log(res)
				})
			}









			// 选择所属工会
			let texture = ref([])
			const yes = () => {
				form.choose = options.value
				visible.value = false;
			};
			const visible = ref(false);
			// 获取组织列表信息
			const messge = () => {
				ZClist().then((res) => {
					console.log(res)
					texture.value = res.data.data.manageUnionList
				})
			}
			const defaultProps = {
				children: 'unionDtoList',
				label: 'unionName',
			}
			// 获取工会code
			let gaincode = ref('')
			// 树形选择
			let options = ref('')
			let dendrogram = (node) => {
				options.value = node.unionName
				form.choose = options.value
				gaincode.value = node.unionCode
			}













			// 选择时间
			const defaultTime2 = [
				new Date(2000, 1, 1, 0, 0, 0),
				new Date(2000, 2, 1, 23, 59, 59),
			]
			// 二维码
			const behavior = ref(false)
			// 商户号
			const behaviour = ref(false)
			// 收费方式
			const manner = (value) => {
				if (value == 1) {
					behavior.value = true
					behaviour.value = false
					form.commercial = ''
					form.payment = ''
					location1.value = ''
					qingkong1.value.clearFiles()
					styA1.value = 0
					styD1.value = 0
					// rules.forEch
				} else if (value == 2) {
					behavior.value = false
					behaviour.value = true
					form.commercial = ''
					form.payment = ''
					location1.value = ''
					qingkong1.value.clearFiles()
					styA1.value = 0
					styD1.value = 0
				}
				// console.log(rules)
			}




			// 报名时间
			const value1 = ref([
				new Date(2000, 10, 10, 10, 10),
				new Date(2000, 10, 11, 10, 10),
			])



			// 生日限制时间
			const value2 = ref([
				new Date(2000, 10, 10, 10, 10),
				new Date(2000, 10, 11, 10, 10),
			])

			let data = ref(null);
			// 按钮加载
			const ConfirmLoading = ref(false)






			// 二维码图片上传
			// 清空图片
			const qingkong1 = ref('')
			// 上传图片
			const header1 = ref({
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			})
			// 获取上传图片成功地址
			let location1 = ref('')
			const succ1 = (res) => {
				location1.value = res.data
				console.log(res)
			}
			// 自定义上传
			const httpreqst1 = (param) => {
				console.log(param)
				console.log(param.file.name)
				data.value = {
					imageFile: param.file.namefileList
				}
				// Pictures(data).then((res)=>{
				//   console.log(res)
				// })
			}
			const styA1 = ref(0)

			const change1 = (res, fileList) => {
				// console.log(res)
				styA1.value = fileList.length
				form.scanning = fileList
				// console.log(fileList)
			}
			const beforeImageUpload1 = (File) => {
				console.log(File)
				let isLt2M = File.size / 1024 / 1024 < 1
				return new Promise((resolve) => {
					if (isLt2M) {
						resolve(File)
					}
					imageConversion.compressAccurately(File, 930).then((res) => {
						resolve(res);
						// data.value = File.name
					});
				})
			}
			// 图片放大
			const dialogVisible1 = ref(false)
			let dialogImageUrl1 = ref('')
			const onpreview1 = (File) => {
				dialogVisible1.value = true
				dialogImageUrl1.value = File.url
				console.log(File)
			}
			// 删除图片
			const beforeremove1 = (File, fileList) => {
				// console.log(File)
				// console.log(fileList)
				styA1.value = 0
				styD1.value = 0
			}
			// const Remove1 =(file)=>{

			// }






			// 主图上传
			// 清空图片
			const qingkong = ref('')
			// 上传图片
			const header = ref({
				timeout: 10000,
				'accessToken': sessionStorage.getItem('token')
			})
			// 获取上传图片成功地址
			let location = ref('')
			const succ = (res) => {
				location.value = res.data
				console.log(res)
			}
			// 自定义上传
			const httpreqst = (param) => {
				console.log(param)
				console.log(param.file.name)
				data.value = {
					imageFile: param.file.namefileList
				}
				// Pictures(data).then((res)=>{
				//   console.log(res)
				// })
			}
			const styA = ref(0)

			const change = (res, fileList) => {
				// console.log(res)
				styA.value = fileList.length
				form.breviary = fileList
				// console.log(fileList)
			}
			const beforeImageUpload = (File) => {
				console.log(File)
				let isLt2M = File.size / 1024 / 1024 < 1
				return new Promise((resolve) => {
					if (isLt2M) {
						resolve(File)
					}
					imageConversion.compressAccurately(File, 930).then((res) => {
						resolve(res);
						// data.value = File.name
					});
				})
			}
			// 图片放大
			const dialogVisible = ref(false)
			let dialogImageUrl = ref('')
			const onpreview = (File) => {
				dialogVisible.value = true
				dialogImageUrl.value = File.url
				console.log(File)
			}
			// 删除图片
			const beforeremove = (File, fileList) => {
				console.log(File)
				console.log(fileList)
				// location.value=''
				styA.value = 0
				styD.value = 0
			}
			const Remove = (file) => {
				console.log(file)
			}




			// 提交 按钮
			// 关闭当前页
			const store = useStore();
			const current = ref('')
			const expression = ref([])
			const Indexs = ref(null)


			const router = useRouter();
			const onSubmit = () => {




				// console.log('微信商户',behaviour.value)
				// console.log('收款二维码',behavior.value)
				// console.log('缩略图地址',location.value)
				// console.log('类型',form.sex)
				// console.log('是否发布',form.are)
				// console.log('上传附件',address.value)



				// console.log('编辑器内容',form.copyreader)
				if(valueHtml.value=='<p><br></p>'){
				  form.remark=''
				}else{
				  form.remark=valueHtml.value
				}

				// 是否发布
				let Srelease = ref('')
				// 收款类型
				let gathering = ref('')








				ruleFormRef.value.validate((valid) => {
					console.log(valid)
					if (valid == true) {
						// 开始时间
						var b = ref(new Date(form.value1[0]))
						var begin = ref('')
						begin.value = b.value.getFullYear() + '-' + (b.value.getMonth() + 1) + '-' + b.value
							.getDate() + ' ' + b.value.getHours() + ':' + b.value.getMinutes() + ':' + b.value
							.getSeconds();
						var start = ref(new Date(begin.value).getTime() / 1000)
						// 截至时间
						var d = ref(new Date(form.value1[1]))
						var finish = ref('')
						finish.value = d.value.getFullYear() + '-' + (d.value.getMonth() + 1) + '-' + d.value
							.getDate() + ' ' + d.value.getHours() + ':' + d.value.getMinutes() + ':' + d.value
							.getSeconds();
						var over = ref(new Date(finish.value).getTime() / 1000)
						// 生日限制开始时间
						var c = ref(new Date(form.value2[0]))
						var begin1 = ref('')
						begin1.value = c.value.getFullYear() + '-' + (c.value.getMonth() + 1) + '-' + c.value
							.getDate() + ' ' + c.value.getHours() + ':' + c.value.getMinutes() + ':' + c.value
							.getSeconds();
						var start1 = ref(new Date(begin1.value).getTime() / 1000)
						// 截至时间
						var e = ref(new Date(form.value2[1]))
						var finish1 = ref('')
						finish1.value = e.value.getFullYear() + '-' + (e.value.getMonth() + 1) + '-' + e.value
							.getDate() + ' ' + e.value.getHours() + ':' + e.value.getMinutes() + ':' + e.value
							.getSeconds();
						var over1 = ref(new Date(finish1.value).getTime() / 1000)
						// 是否发布
						if (form.are == '是') {
							Srelease.value = 1
						} else if (form.are == '否') {
							Srelease.value = 2
						} else if (form.are == 1) {
							Srelease.value = 1
						} else if (form.are == 2) {
							Srelease.value = 2
						}
						// 收款类型
						if (form.classify == '二维码收款') {
							gathering.value = 1
						} else if (form.classify == '小程序收款') {
							gathering.value = 2
						} else if (form.classify == 1) {
							gathering.value = 1
						} else if (form.classify == 2) {
							gathering.value = 2
						}
						console.log('标题', form.name)
						console.log('缩略图地址', location.value)
						console.log('开始时间', start.value)
						console.log('结束时间', over.value)
						console.log('工会code', gaincode.value)
						console.log('是否发布', Srelease.value)
						console.log('报名说明', form.remark)
						console.log('收款类型', gathering.value)
						console.log('微信商户号', form.commercial)
						console.log('商户key', form.payment)
						console.log('微信二维码', location1.value)
						let data = {
							childCustodyId: sessionStorage.getItem('ZNBMredact'),
							// 标题
							title: form.name,
							// 标题图片
							imageInfo: location.value,
							// 开始报名
							startTime: start.value,
							// 结束时间
							endTime: over.value,
							// 生日限制开始报名
							birthdayStartTime: start1.value,
							// 生日限制结束时间
							birthdayEndTime: over1.value,
							// 工会本编码code
							unionCode: gaincode.value,
							// 是否公开
							state: Srelease.value,
							// 收费类型
							payType: gathering.value,
							// 收款二维码
							payImage: location1.value,
							// 微信商户号
							mchId: form.commercial,
							// 微信key
							mchKey: form.payment,
							// 报名说明
							reason: form.remark,
						}
						console.log(data)
						ConfirmLoading.value = true
						ZNcompile(qs.parse(data)).then((res) => {
							console.log(res)
							if (res.data.code == 0) {
								ElMessage({
									message: '提交成功',
									type: "success",
									center: true,
								});
								setTimeout(() => {
									// // 清空标题
									//   form.name=''
									//   // 清空时间报名
									//   form.value1=''
									//   // 清空所属工会
									//   form.choose=''
									//   gaincode.value=''
									//   // 清空是否发布
									//   form.are=''
									//   // 清空报名说明
									//   form.remark=''
									//   // 清空收款方式
									//   form.classify=''
									//   // 清空主图图片
									//   location.value=''
									//   qingkong.value.clearFiles()
									//   styA.value=0
									//   // 清空二维码
									//   location1.value=''
									//   qingkong1.value.clearFiles()
									//   styA1.value=0
									//   // 清空商户号
									//   form.commercial=''
									//   // 清空key
									//   form.payment=''
									current.value = route.path
									expression.value = store.state.tagsList

									expression.value.forEach((item, index) => {
										if (current.value == item.path) {
											Indexs.value = index
										}
									})
									store.state.tagsList.splice(Indexs.value, 1)
									router.push({
										name: 'ZNtrustee',
										params: {
											userId: 1
										}
									});
									ConfirmLoading.value = false
								}, 500)
							} else if (res.data.code !== 0) {
								ElMessage({
									message: res.data.msg,
									type: "error",
									center: true,
								});
								ConfirmLoading.value = false
							}
						})
					} else {
						ElMessage({
							message: "提交失败",
							type: "error",
							center: true,
						});
						ConfirmLoading.value = false
					}
					// console.log(form)
				})
			}
			// 编辑器实例，必须用 shallowRef
			const editorRef = shallowRef()

			// 内容 HTML
			const valueHtml = ref('')
			let translate = ref(2)
			onActivated(() => {
				translate.value = route.params.UserId
				//  console.log(translate.value)
				if (translate.value == 1) {
					form.value1 = []
					loading.value = true
					approve.value = [{}]
					approve1.value = [{}]
					copyreader()
					messge()
				}
			})
			// 模拟 ajax 异步获取内容
			onMounted(() => {
				messge()
				copyreader()
			})

			const toolbarConfig = {
				toolbarKeys: [
					'bold', // 加粗
					'italic', // 斜体
					'through', // 删除线
					'underline', // 下划线
					'bulletedList', // 无序列表
					'numberedList', // 有序列表
					'color', // 文字颜色
					'fontSize', // 字体大小
					'lineHeight', // 行高
					'uploadImage', // 上传图片
					'delIndent', // 缩进
					'indent', // 增进
					'deleteImage', //删除图片
					'justifyCenter', // 居中对齐
					'justifyJustify', // 两端对齐
					'justifyLeft', // 左对齐
					'justifyRight', // 右对齐
					'undo', // 撤销
					'redo', // 重做
					'clearStyle', // 清除格式
				],
				excludeKeys: [
					'insertImage', // 网络图片
					'bgColor', // 背景色
					'blockquote', // 引用
					'codeBlock', // 代码段
					'emotion', // 表情
					'fontFamily', // 字体
					'headerSelect', // 标题 
					'fullScreen', // 全屏
				],
			}
			// 富文本上传图片地址
			// let fils=ref('')
			// placeholder: '请输入内容...',
			const editorConfig = {
				MENU_CONF: {}
			}
			// 视频上传
			editorConfig.MENU_CONF['uploadVideo'] = {
				// 上传视频的配置
				// 接口
				server: '/admin/api/v1/activity/saveEditorImage',
				// 请求头
				headers: {
					timeout: 10000,
					'accessToken': sessionStorage.getItem('token')
				},
				// 限制上传视频大小
				maxFileSize: 104857600, // 100M
				//上传字段 
				fieldName: 'imageFile',
				maxNumberOfFiles: 1,
				// 上传前回调
				onBeforeUpload(file) {
					console.log(file)
					let fileObj = Object.values(file)[0].data
					console.log(fileObj)
					// 判断视频是否大于100M
					let isLt2M = Object.values(file)[0].data.size
					if (isLt2M < 104857600) {
						return true
					} else if (isLt2M > 104857600) {
						ElMessage({
							message: '视频大小不能超过100MB,请重新上传',
							type: "warning",
							center: true,
						});
						return false
					}
				},
				// 上传成功后回调
				onSuccess(file, res) {
					console.log(file)
					console.log(res)
				},
				// 上传失败回调
				onError(file, res) {
					console.log(file)
					console.log(res)
					if (file.size > 104857600) {
						ElMessage({
							message: '视频大小不能超过100MB,请重新上传',
							type: "warning",
							center: true,
						});
					} else {
						ElMessage({
							message: '上传失败，请稍后再试',
							type: "warning",
							center: true,
						});
					}
				},
			}
			
			
			// 图片上传
			editorConfig.MENU_CONF['uploadImage'] = {
				// 上传图片的配置 
				// 接口
				server: '/admin/api/v1/activity/saveEditorImage',
				// 请求头
				headers: {
					timeout: 10000,
					'accessToken': sessionStorage.getItem('token')
				},
				maxFileSize: 1 * 1024 * 1024, // 1M
				//上传字段 
				fieldName: 'imageFile',
				// meta: {
				//   imageFile:fils
				// },
				// 最多可上传几个文件，默认为 100
				maxNumberOfFiles: 1,
				// 上传前回调
				onBeforeUpload(file) {
					console.log(file)
					let fileObj = Object.values(file)[0].data
					console.log(fileObj)
					// 压缩图片
					let isLt2M = Object.values(file)[0].data.size
					if (isLt2M < 1048576) {
						return true
					} else if (isLt2M > 1048576) {
						ElMessage({
							message: '图片大小不能超过1MB,请重新上传',
							type: "warning",
							center: true,
						});
						return false
					}
				},
				// 上传成功后回调
				onSuccess(file, res) {
					console.log(file)
					console.log(res)
				},
				// 上传失败回调
				onError() {
					ElMessage({
						message: '图片大小不能超过1MB,请重新上传',
						type: "warning",
						center: true,
					});
				},
			}
			
			// 组件销毁时，也及时销毁编辑器
			onBeforeUnmount(() => {
				const editor = editorRef.value
				if (editor == null) return
				editor.destroy()
			})
			
			const handleCreated = (editor) => {
				editorRef.value = editor // 记录 editor 实例，重要！
				// console.log(editor)
			}
			// 标题验证
			const ruleFormRef = ref(null)
			const form = reactive({
				value1: [],
				// 标题
				name: "",
				// copyreader:"",
				// 是否发布
				are: '',
				// g:'',
				// 说明
				remark: '',
				// 商户微信
				commercial: '',
				// 商户key
				payment: '',
				// 主图图片
				breviary: '',
				// 二维码图片
				scanning: '',
				// 所属工会
				choose: '',
				// 收款类型
				classify: ''
			})
			// 验证表单提示
			const rules = {
				choose: [{
					required: true,
					message: "请选择所属工会",
					trigger: "change"
				}, ],
				breviary: [{
					required: true,
					message: "请上传主图图片",
					trigger: "change"
				}, ],
				remark: [{
					required: true,
					message: "请填写报名说明",
					trigger: "change"
				}, ],
				are: [{
					required: true,
					message: "请选择是否发布",
					trigger: "change"
				}, ],
				// copyreader:[
				//   { required: true, message: "请填写内容", trigger: "change" },
				// ],
				name: [{
					required: true,
					message: "请填写标题",
					trigger: "blur"
				}, ],
				classify: [{
					required: true,
					message: "请选择收费方式",
					trigger: "change"
				}, ],
				value1: [{
					required: true,
					message: "请选择时间",
					trigger: "change"
				}, ],
				value2: [{
					required: true,
					message: "请选择生日限制时间",
					trigger: "change"
				}, ],
			}
			return {
				// 加载中模块
				loading,
				// 二维码图片
				styD1,
				approve1,
				// 标题图片
				styD,
				approve,
				// 所属工会选择
				yes,
				dendrogram,
				defaultProps,
				texture,
				visible,
				defaultTime2,
				// 收费方式
				behaviour,
				behavior,
				manner,



				value1,
				value2,
				qingkong,
				qingkong1,
				header,
				header1,
				succ,
				succ1,
				data,
				// 按钮加载
				ConfirmLoading,
				// 上传图片
				httpreqst,
				httpreqst1,
				dialogVisible,
				dialogVisible1,
				dialogImageUrl,
				dialogImageUrl1,
				onpreview,
				onpreview1,
				beforeremove,
				beforeremove1,
				Remove,
				// Remove1,
				styA,
				styA1,
				change,
				change1,
				beforeImageUpload,
				beforeImageUpload1,
				// handlePictureCardPreview,
				// 编辑器
				onSubmit,
				editorRef,
				valueHtml,
				mode: 'default',
				toolbarConfig,
				editorConfig,
				handleCreated,
				// 标题验证
				ruleFormRef,
				form,
				rules
			}
		}
	}
</script>
<style lang='scss' scoped>
	::v-deep .el-textarea {
		position: relative;
		display: inline-block;
		width: 50%;
		vertical-align: bottom;
		font-size: var(--el-font-size-base);
	}

	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	::v-deep .chart-card {
		height: calc(100vh - 145px);
		overflow: auto;
	}

	::v-deep .subject {
		margin-left: 3px;
		font-size: var(--el-form-label-font-size);
		font-weight: bold;
		margin-bottom: 5px;
		color: var(--el-text-color-regular);

		.xingxing {
			color: var(--el-color-danger);
		}
	}

	// .w-e-text-container [data-slate-editor] p{
	//   margin-top: 22px;
	//   margin-bottom: 22px;
	// }
	::v-deep .el-button {
		width: 100px;
		height: 40px;
		font-size: 16px;
		/* margin-left: 100px; */
	}

	::v-deep .el-form-item__content {
		align-items: flex-end;
	}

	::v-deep .referrals {
		color: #cccccc;
		margin-left: 5px;
		font-weight: 500;
	}

	::v-deep .styB .el-upload--picture-card {
		width: 110px;
		height: 110px;
		line-height: 110px;
	}

	::v-deep .styC .el-upload--picture-card {
		display: none;
	}

	::v-deep .el-form-item {
		align-items: center;
	}

	::v-deep .aaaa {
		width: 50%;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	::v-deep .el-main {
		overflow: auto !important;
	}

	::v-deep .el-card.is-always-shadow {
		width: 95%;
		margin: auto;
		margin-top: 10px;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
		overflow: auto !important;
	}
</style>
<style scoped>
	::v-deep .upload-demo {
		display: flex;
		width: 100%;
	}

	::v-deep .upload-demo .el-upload-list {
		display: flex;
		width: 50%;
	}

	::v-deep .el-input {
		height: 44px !important;
	}

	::v-deep .el-form-item__label {
		width: 110px !important;
		font-weight: bold;
	}
</style>